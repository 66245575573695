import { wrappedFetch, wrappedGet } from "./urlFuncs";
import {
  GET_CAT,
  GET_PROD,
  GET_PROD_CAT,
  GET_ART,
  SEARCH_PROD,
  GET_ORDERS,
  SEARCH_ORDERS,
  GET_COLLAB_REQUESTS,
  POST_COLLAB,
  ORDER_DETAILS,
  GET_ALL_USER,
  SEARCH_USER,
  BLOCK_UNBLOCK_USER,
  DELETE_USER,
  ADD_COLLABORATOR,
  GET_ALL_MUSIC,
  GET_MUSIC_REQUEST,
  ACCEPT_MUSIC,
  DELETE_MUSIC,
  GET_ALL_PROMOCODE,
  DELETE_ALL_PROMOCODE,
  GET_PROMOCODE_DETAIL,
  ADD_PROMOCODE,
  EDIT_PROMOCODE,
  UPDATE_PROMOCODE_STATUS,
  GET_ALL_ACCEPTED_EVENT,
  GET_REQUESTED_EVENT,
  RESPOND_TO_EVENT,
  GET_EVENT_DETAILS,
  ADD_EVENT,
  ORDER_STATUS,
  GET_ALL_DASHBOARD_DATA,
  CHANGE_ADMIN_PASSWORD,
  GET_ADMIN_PROFILE,
  EDIT_ADMIN_PROFILE,
  SEARCH_PROMOCODE,
  SEARCH_USER_AVAILABLE,
  FINALISE_MULTIPART,
  INITIALISE_MULTIPART,
} from "./urlConstants";

export const get_cat = () => {
  return wrappedGet(GET_CAT, "GET");
};

export const add_cat = (data) => {
  return wrappedFetch(GET_CAT, "POST", data);
};

export const edit_cat = (catId, data) => {
  return wrappedFetch(`${GET_CAT}/${catId}`, "PATCH", data);
};

export const delete_cat = (catId) => {
  return wrappedGet(`${GET_CAT}/${catId}`, "DELETE");
};

export const get_prod = (pageNumber, pageLimit) => {
  // will need it for limit and skip
  return wrappedFetch(`${GET_PROD}?pageNumber=${pageNumber}&pageLimit=${pageLimit}`, "GET");
};

export const fetch_prod = (data) => {
  return wrappedGet(`${GET_PROD}/${data}`, "GET");
};

export const get_prod_by_cat = (data) => {
  // will need it for limit and skip
  return wrappedGet(`${GET_PROD_CAT}/${data}`, "GET");
};



export const delete_prod = (data) => {
  return wrappedGet(`${GET_PROD}/${data}`, "DELETE");
};

export const get_art = (pageNumber, pageLimit) => {
  return wrappedGet(`${GET_ART}?pageNumber=${pageNumber}&pageLimit=${pageLimit}`, "GET");
};

export const get_single_art_type = (artTypeId) => {
  return wrappedGet(`${GET_ART}/${artTypeId}`, "GET");
};

export const delete_art_type = (artTypeId) => {
  return wrappedGet(`${GET_ART}/${artTypeId}`, "DELETE");
};

export const add_art_type = (data) => {
  return wrappedFetch(GET_ART, "POST", data);
};

export const edit_art_type = (artTypeId, data) => {
  return wrappedFetch(`${GET_ART}/${artTypeId}`, "PATCH", data);
};

export const add_product = (data) => {
  return wrappedFetch(GET_PROD, "POST", data, {
    "Content-Type": "multipart/form-data",
  });
};

export const edit_product = (data, productID) => {
  return wrappedFetch(`${GET_PROD}/${productID}`, "PATCH", data, {
    "Content-Type": "multipart/form-data",
  });
};

export const search_product = (data) => {
  return wrappedGet(`${SEARCH_PROD}/${data}`, "GET");
};

export const get_all_orders = (pageNumber, pageLimit, orderStatus) => {
  return wrappedGet(`${GET_ORDERS}?pageNumber=${pageNumber}&pageLimit=${pageLimit}&orderStatus=${orderStatus}`, "GET")
}


export const search_orders = (orderID) => {
  return wrappedGet(`${SEARCH_ORDERS}?orderId=${orderID}`, "GET")
}

export const get_order_details = (id) => {
  return wrappedGet(`${ORDER_DETAILS}?id=${id}`, "GET")
}


export const change_order_status = (data) => {
  return wrappedFetch(ORDER_STATUS, 'POST', data);
};


// user management routes

export const get_all_users = (pageNumber, pageLimit, filter) => {
  return wrappedFetch(`${GET_ALL_USER}?pageNumber=${pageNumber}&pageLimit=${pageLimit}&filter=${filter}`, "GET")
}

export const search_user = (user, filter) => {
  return wrappedFetch(`${SEARCH_USER}?user=${user}&filter=${filter}`, "GET")
}

export const block_unblock_user = (data) => {
  return wrappedFetch(BLOCK_UNBLOCK_USER, "POST", data, {
    'Content-Type': 'application/x-www-form-urlencoded'
  },)
}

export const delete_user = (data) => {
  return wrappedFetch(DELETE_USER, "POST", data, {
    'Content-Type': 'application/x-www-form-urlencoded'
  },)
}

// collaborators routes 

export const add_collaborator = (data) => {
  return wrappedFetch(ADD_COLLABORATOR, "POST", data)
}

export const get_collab_request = (pageNumber, pageLimit) => {
  return wrappedFetch(`${GET_COLLAB_REQUESTS}?pageNumber=${pageNumber}&pageLimit=${pageLimit}`, "GET")
}

export const post_collab = (id, data) => {
  return wrappedFetch(`${POST_COLLAB}?id=${id}`, 'PUT', data);
};


// MUSIC MANAGEMENT

export const get_music_list = (pageNumber, pageLimit) => {
  return wrappedFetch(`${GET_ALL_MUSIC}?pageNumber=${pageNumber}&pageLimit=${pageLimit}`, "GET")
}

export const get_music_request = (pageNumber, pageLimit) => {
  return wrappedFetch(`${GET_MUSIC_REQUEST}?pageNumber=${pageNumber}&pageLimit=${pageLimit}`, "GET")
}

export const accept_music_request = (data) => {
  return wrappedFetch(ACCEPT_MUSIC, "POST", data, {
    'Content-Type': 'application/x-www-form-urlencoded'
  },)
}

export const delete_music = (musicId) => {
  return wrappedFetch(`${DELETE_MUSIC}?musicId=${musicId}`, 'GET');
};

// PROMO CODE

export const get_promo_list = (pageNumber, pageLimit) => {
  return wrappedFetch(`${GET_ALL_PROMOCODE}?pageNumber=${pageNumber}&pageLimit=${pageLimit}`, "GET")
}

export const get_promo_details = (promoId) => {
  return wrappedFetch(`${GET_PROMOCODE_DETAIL}?promoId=${promoId}`, 'GET');
};

export const delete_promocode = (data) => {
  return wrappedFetch(DELETE_ALL_PROMOCODE, 'POST', data);
};

export const add_promocode = (data) => {
  return wrappedFetch(ADD_PROMOCODE, 'POST', data);
};
export const edit_promocode = (data) => {
  return wrappedFetch(EDIT_PROMOCODE, 'POST', data);
};

export const search_promocode = (search) => {
  return wrappedFetch(`${SEARCH_PROMOCODE}?promocode=${search}`, 'GET');
};

export const update_promocode = (data) => {
  return wrappedFetch(UPDATE_PROMOCODE_STATUS, 'POST', data);
};

// EVENT MANAGEMENT

export const get_accepted_event_list = (pageNumber, pageLimit) => {
  return wrappedFetch(`${GET_ALL_ACCEPTED_EVENT}?page=${pageNumber}&limit=${pageLimit}`, "GET")
}

export const get_requested_event_list = (pageNumber, pageLimit) => {
  return wrappedFetch(`${GET_REQUESTED_EVENT}?page=${pageNumber}&limit=${pageLimit}`, "GET")
}

export const add_event = (data) => {
  return wrappedFetch(ADD_EVENT, "POST", data)
}

export const respond_to_event = (data) => {
  return wrappedFetch(RESPOND_TO_EVENT, "POST", data);
}

export const get_event_details = (eventId) => {
  return wrappedFetch(`${GET_EVENT_DETAILS}?eventId=${eventId}`, "GET")
}

// Dashboard 

export const get_dashboard_data = () => {
  return wrappedGet(`${GET_ALL_DASHBOARD_DATA}`, "GET")
}

// profile update

export const get_admin_profile = () => {
  return wrappedGet(`${GET_ADMIN_PROFILE}`, "GET")
}

export const update_admin_profile = (data) => {
  return wrappedFetch(EDIT_ADMIN_PROFILE, "POST", data)
}

export const change_current_password = (data) => {
  return wrappedFetch(CHANGE_ADMIN_PASSWORD, "POST", data);
}

// upload music 

export const Search_User_available = (email) => {
  return wrappedGet(`${SEARCH_USER_AVAILABLE}?artist=${email}`, "GET")
}  

export const initialiseMultipart = (data) => {
  return wrappedFetch(INITIALISE_MULTIPART, "POST", data);
}

export const finaliseMultipart = (data) => {
  return wrappedFetch(FINALISE_MULTIPART, "POST", data, {
		"Content-Type": "multipart/form-data",
	});
}
