import React, { useState } from "react";
import { STRINGS } from "./strings";
import { InputFile } from "../../components/formelements/InputFile";
import { TextInput } from "../../components/formelements/TextInput";
import Button from "../../components/formelements/Button";
import CustomPhoneInput from "../../components/formelements/CustomPhoneInput";
import { Title } from "../../components/title/Title";
import { AddCollaboratorValidations } from "../../utils/validations/joiValidations";
import { add_collaborator } from "../../utils/api/utilAPI";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

const AddCollaborators = ({ handleModalOpen, onCollabIdReceived, isCollabReg , setActiveStep , prevEmail}) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: prevEmail ? prevEmail : "",
    phoneNumber: "",
    countryCode: null,
  });
  const [validations, setValidations] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    profileImage: "",
  });
  const [profileImage, setProfileImage] = useState("")
  const [image, setImage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePhoneChange = (value, data) => {
    setFormData({
      ...formData,
      phoneNumber: value,
      countryCode: data?.dialCode,
    });
  };

  const handleProfileImage = (e) => {
    if (e.target.files[0]) {
      const maxSize = 200 * 1024;
      const file = e.target.files[0];
      if (file.size <= maxSize) {
        setProfileImage(file);
        setImage(URL.createObjectURL(file));
        setValidations({
          ...validations,
          profileImage: "", // Clear image validation error
        });
      } else {
        setProfileImage("");
        setImage("");
        setValidations({
          ...validations,
          profileImage: "Image must be less than 200kb",
        });
        return;
      }
    }
  };

  const handleFileDelete = () => {
    setProfileImage("");
    setImage("");
    setValidations({
      ...validations,
      profileImage: "", // Clear image validation error
    });
  };

  const handleAddCollab = async () => {
    if(isCollabReg){
      setActiveStep(2);
    } else {
    const data = new FormData();
    data.append("firstName", formData?.firstName);
    data.append("lastName", formData?.lastName);
    data.append("email", formData?.email);
    data.append("phoneNumber", (formData?.phoneNumber)?.slice(formData?.countryCode.length));
    data.append("countryCode", formData?.countryCode);

    if (profileImage) {
      data.append("profilePicture", profileImage);
    }

    try {
      const response = await add_collaborator(data);
      const collabId = response?.data?.data?.id
      toast.success(response?.data?.message, {
        toastId: "added collaborator successfully",
      });
      onCollabIdReceived(collabId);
      handleModalOpen(false);
    } catch (error) {
      toast.error(error.response?.data?.message, {
        toastId: "add collaborator failed",
      });
    }
  }};

  const handleAddButton = (e) => {
    e.preventDefault();
    const { error } = AddCollaboratorValidations.validate({
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email || prevEmail,
      phoneNumber: formData.phoneNumber,
    });
    if (error) {
      const validationErrors = error.details.reduce((acc, curr) => {
        acc[curr.path[0]] = curr.message;
        return acc;
      }, {});
      setValidations(validationErrors);
    } else {
      setValidations({});
      handleAddCollab();
    }
  };
  return (
    <div className="px-4">
      <div className="flex gap-4 w-full">
        <div className="w-1/2">
          <TextInput
            type="text"
            label={STRINGS.FIRST_NAME}
            placeholder={STRINGS.FIRST_NAME}
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
            error={validations?.firstName}
          />
        </div>
        <div className="w-1/2">
          <TextInput
            type="text"
            label={STRINGS.LAST_NAME}
            placeholder={STRINGS.LAST_NAME}
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
            error={validations?.lastName}
          />
        </div>
      </div>

      <TextInput
        className="w-1/2"
        type="text"
        label={STRINGS.EMAIL}
        placeholder={STRINGS.EMAIL}
        name="email"
        value={`${prevEmail ? prevEmail : formData.email}`}
        onChange={handleInputChange}
        error={validations?.email}
      />
      <div className="flex flex-col gap-2 mb-3">
        <Title
          pagetitle={"Phone Number"}
          fontsize="16px"
          fontWeight="500"
          color="#060709"
          border={false}
        />
        <CustomPhoneInput
          value={formData.phoneNumber}
          disabled={false}
          onChange={handlePhoneChange}
          error={validations.phoneNumber}
        />
      </div>
      <div className="flex flex-col gap-2 mb-3">
        <Title
          pagetitle={"Profile Image"}
          fontsize="16px"
          fontWeight="500"
          color="#060709"
          border={false}
        />
        <div className="flex gap-5">
          <InputFile
            accept="image/x-png,image/jpeg,image/jpg"
            type="file"
            placeholder={"Upload Profile Image"}
            subtext={"Image must be less than 200kb"}
            error={validations?.profileImage}
            onChange={handleProfileImage}
          />
          {image && <div
            style={{
              flexShrink: "0",
              position: "relative",
              width: "8rem",
              height: "8rem",
              marginRight: "2rem",
            }}
          >
            <img
              className="w-[inherit] h-[inherit]"
              src={image}
              alt=""
            />
            <button
              style={{
                position: "absolute",
                top: "-5%",
                right: "-5%",
                width: "1.5rem",
                height: "1.5rem",
                display: "flex",
                cursor: "pointer",
              }}
              onClick={handleFileDelete}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="28" cy="28" r="16" fill="white" />
                <path
                  d="M27.9994 4.66602C15.1427 4.66602 4.66602 15.1427 4.66602 27.9994C4.66602 40.856 15.1427 51.3327 27.9994 51.3327C40.856 51.3327 51.3327 40.856 51.3327 27.9994C51.3327 15.1427 40.856 4.66602 27.9994 4.66602ZM35.8393 33.366C36.516 34.0427 36.516 35.1627 35.8393 35.8393C35.4893 36.1894 35.046 36.3527 34.6027 36.3527C34.1594 36.3527 33.716 36.1894 33.366 35.8393L27.9994 30.4727L22.6327 35.8393C22.2827 36.1894 21.8393 36.3527 21.396 36.3527C20.9527 36.3527 20.5094 36.1894 20.1594 35.8393C19.4827 35.1627 19.4827 34.0427 20.1594 33.366L25.526 27.9994L20.1594 22.6327C19.4827 21.956 19.4827 20.836 20.1594 20.1594C20.836 19.4827 21.956 19.4827 22.6327 20.1594L27.9994 25.526L33.366 20.1594C34.0427 19.4827 35.1627 19.4827 35.8393 20.1594C36.516 20.836 36.516 21.956 35.8393 22.6327L30.4727 27.9994L35.8393 33.366Z"
                  fill="#E6355C"
                />
              </svg>
            </button>
          </div>}
        </div>

      </div>
      <div className="flex justify-start gap-4 my-3">
        <Button style={{ border: "0" }} type="submit" onClick={handleAddButton}>
          {isCollabReg ? "Upload Music" : "Submit"}
        </Button>
      </div>
    </div>
  );
};

AddCollaborators.propTypes = {
  handleModalOpen: PropTypes.func,
  onCollabIdReceived: PropTypes.func,   
  isCollabReg: PropTypes.bool,
  setActiveStep: PropTypes.func,  
};

export default AddCollaborators;
